import styled, { breakpoints, css } from "@xstyled/styled-components"

const Bg = styled.aside(
  breakpoints({
    xs: css`
      display: none;
    `,
    lg: css`
      display: block;
      background-image: url(${props => props.image});
      background-size: cover;
      background-position: center;
      height: 100%;
      position: fixed;
      top: 0;
      right: 0;
      width: calc(50% - 20px);
    `,
  })
)

export default Bg
