import React from "react"

import Button from "@atoms/Button"

import ButtonContainer from "./styles"

const BottomLinks = () => {
  return (
    <ButtonContainer>
      <Button my={1} styleVariant="secondary" href="mailto:chris@biron.io">
        CHRIS@BIRON.IO
      </Button>
      <Button my={1} href="https://instagram.com/biron.io">
        instagram
      </Button>
      <Button my={1} href="https://twitter.com/biron_io">
        twitter
      </Button>
      <Button my={1} href="https://www.linkedin.com/in/biron-io/">
        linkedin
      </Button>
    </ButtonContainer>
  )
}

export default BottomLinks
