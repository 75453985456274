import React from "react"
import { Box } from "@xstyled/styled-components"
import { RichText } from "prismic-reactjs"
import PropTypes from "prop-types"

import { linkResolver } from "@utils/linkResolver"

const MainDescription = ({ desc }) => {
  return (
    <Box fontSize={{ xs: "6vw", md: "5vw", lg: "3.026vw" }} mt={15}>
      {RichText.render(desc, linkResolver)}
    </Box>
  )
}

MainDescription.propTypes = {
  desc: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default MainDescription
