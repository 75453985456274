import React from "react"
import PropTypes from "prop-types"

import Bg from "./styles"

const BgImage = ({ image }) => {
  return <Bg image={image} />
}

BgImage.propTypes = {
  image: PropTypes.string.isRequired,
}

export default BgImage
