import styled, { breakpoints, css } from "@xstyled/styled-components"

import { MainLink } from "@atoms/Typography"

const Title = styled(MainLink)(
  breakpoints({
    xs: css`
      font-size: 2vw;
    `,
    md: css`
      font-size: 1.5vw;
    `,
    lg: css`
      font-size: 0.61vw;
    `,
  })
)

export default Title
