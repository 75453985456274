import styled from "@xstyled/styled-components"

const ButtonContainer = styled.div`
  margin-bottom: 5;

  a {
    transition: 0.2s;
  }

  &:hover {
    a {
      opacity: 0.5;
    }
  }

  a:hover {
    opacity: 1;
  }

  a:first-child {
    opacity: 1;
  }
`

export default ButtonContainer
