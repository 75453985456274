import React from "react"
import PropTypes from "prop-types"

import Main from "./styles"

const MainContainer = ({ children }) => {
  return <Main>{children}</Main>
}

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainContainer
