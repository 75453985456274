import React from "react"
import { Box } from "@xstyled/styled-components"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Layout from "@components/layout"
import SEO from "@atoms/Seo"
import PageWrap from "@atoms/PageWrap"

import MainDescription from "@organisms/About/MainDescription"
import SecondaryDescription from "@organisms/About/SecondaryDescription"
import SelectedClients from "@organisms/About/SelectedClients"
import BottomLinks from "@organisms/About/BottomLinks"
import MainContainer from "@organisms/About/MainContainer"
import BgImage from "@organisms/About/BgImage"

const AboutPage = ({ data, location }) => {
  const doc = data.prismic.about.edges.slice(0, 1).pop()
  if (!doc) return null
  const about = doc.node

  return (
    <Layout location={location}>
      <SEO title="About" />
      <Box backgroundColor="creamLight">
        <PageWrap>
          <Box row mx={-2}>
            <Box col={{ xs: 1, md: 1, lg: 1 / 2 }} px={2}>
              <MainContainer>
                {about.main_description && (
                  <MainDescription desc={about.main_description} />
                )}
                {about.secondary_description && (
                  <SecondaryDescription desc={about.secondary_description} />
                )}
              </MainContainer>
              {about.selected_clients && (
                <SelectedClients clients={about.selected_clients} />
              )}
              <BottomLinks />
            </Box>
            <BgImage image={about.side_image && about.side_image.url} />
          </Box>
        </PageWrap>
      </Box>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export const query = graphql`
  {
    prismic {
      about: allAbouts {
        edges {
          node {
            main_description
            secondary_description
            selected_clients
            side_image
          }
        }
      }
    }
  }
`

export default AboutPage
