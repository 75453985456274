import React from "react"
import { RichText } from "prismic-reactjs"
import PropTypes from "prop-types"

import { linkResolver } from "@utils/linkResolver"

import Copy from "./styles"

const SecondaryDescription = ({ desc }) => {
  return (
    <Copy fontSize={{ xs: "4vw", md: "3vw", lg: "1.535vw" }} my={5} mb={5}>
      {RichText.render(desc, linkResolver)}
    </Copy>
  )
}

SecondaryDescription.propTypes = {
  desc: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SecondaryDescription
