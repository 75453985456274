import styled from "@xstyled/styled-components"

const Main = styled.main`
  p {
    line-height: 1.1;
  }

  p + p {
    text-indent: 40px;
  }

  a {
    display: inline;
  }
`

export default Main
