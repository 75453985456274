import React from "react"
import { Box } from "@xstyled/styled-components"
import { RichText } from "prismic-reactjs"
import PropTypes from "prop-types"

import Title from "./styles"

const SelectedClients = ({ clients }) => {
  return (
    <>
      <Title as="h2">Selected Clients</Title>
      <Box fontSize={{ xs: "3.5vw", md: "3vw", lg: "1.68vw" }} mt={1} mb={5}>
        {RichText.asText(clients)}
      </Box>
    </>
  )
}

SelectedClients.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SelectedClients
