import styled from "@xstyled/styled-components"

const Copy = styled.box`
  p {
    line-height: 1.45 !important;
  }

  p + p {
    text-indent: 40px;
  }

  a {
    text-decoration: underline;
  }
`

export default Copy
